/**======================================================================
=========================================================================
Template Name: DashboardKit - Most Complete Bootstrap Admin Template
Author: DashboardKit
Support: dashboardkit[at]gmail[dot]com
File: style.css
=========================================================================
=================================================================================== */
.tab-layout .tab-container {
  display: flex; }
  @media (max-width: 1024px) {
    .tab-layout .tab-container {
      background: inherit; } }

.tab-layout .navbar-wrapper {
  width: calc(280px - 57px); }

.tab-layout .pc-tabcontent:not(.active) {
  display: none; }

.tab-layout .pc-sidebar.light-sidebar .m-header {
  background: transparent; }

.tab-layout .tab-sidemenu {
  width: 57px;
  padding: 80px 4px 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .tab-layout .tab-sidemenu .nav-link {
    cursor: pointer;
    color: #ced4dc;
    position: relative;
    padding: 0.5rem 0.6rem;
    border-radius: 4px;
    line-height: 1;
    text-align: center; }
    .tab-layout .tab-sidemenu .nav-link.img-link {
      padding: 0.5rem 0.5rem; }
    .tab-layout .tab-sidemenu .nav-link i {
      font-size: 24px; }
    .tab-layout .tab-sidemenu .nav-link svg {
      width: 20px;
      height: 20px; }
    .tab-layout .tab-sidemenu .nav-link .material-icons-two-tone {
      font-size: 24px;
      width: 24px;
      height: 24px;
      background-color: #778290; }
  .tab-layout .tab-sidemenu li + li .nav-link {
    margin-top: 20px; }
  .tab-layout .tab-sidemenu li.active .nav-link, .tab-layout .tab-sidemenu li:focus .nav-link, .tab-layout .tab-sidemenu li:hover .nav-link {
    box-shadow: 0 9px 9px -1px rgba(69, 90, 100, 0.1);
    background: #161c25;
    opacity: 1; }
    .tab-layout .tab-sidemenu li.active .nav-link:after, .tab-layout .tab-sidemenu li:focus .nav-link:after, .tab-layout .tab-sidemenu li:hover .nav-link:after {
      content: "";
      position: absolute;
      right: -24px;
      top: calc(50% - 10px);
      border: 10px solid transparent;
      border-left-color: #1c232f;
      z-index: 1; }
  .tab-layout .tab-sidemenu[class*='bg-'] .nav-link {
    color: #fff; }

.tab-layout .pct-customizer {
  display: none; }

@media (max-width: 1024px) {
  .tab-layout .tab-sidemenu {
    z-index: 6;
    overflow: hidden auto;
    height: 100vh; } }
